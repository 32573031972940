import TableTennis from 'assets/img/table-tennis.png';

export default function PlayerReceivesStats({ data }) {
  return (
    <div className="d-flex flex-column align-items-center py-4">
      <div className="relative mb-4">
        <img src={TableTennis} className="block md:hidden" height={80} width={80} />
        <img src={TableTennis} className="hidden md:block" height={140} width={140} />
        <span className="absolute text-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-bold text-xl">
          {data?.TotalService || '-'}
        </span>
      </div>
      <div className="text-primary font-bold mb-1">{data?.Name || '-'}</div>
      <div className="text-xs text-gray-700">Total Receives</div>
    </div>
  );
}
