import PlayerReceivesStats from './components/PlayerReceivesStats';

export default function SummaryStats({ data }) {
  const {
    AnalysisData: { PlayerAStats, PlayerBStats },
    AIData: {
      AIResponse: { RecieveAnalysis },
    },
  } = data;

  return (
    <>
      <div className="grid grid-cols-2 gap-4 mb-4 border-b-1">
        <PlayerReceivesStats data={RecieveAnalysis.PlayerA} />
        <PlayerReceivesStats data={RecieveAnalysis.PlayerB} />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <PlayerStats stats={PlayerAStats} />
        <PlayerStats stats={PlayerBStats} />
      </div>
    </>
  );
}

const PlayerStats = ({ stats }) => {
  return (
    <div className="grid gap-4">
      <div className="d-flex flex-column align-items-center">
        <div className="text-tertiary-100 text-xs mb-2">Total Serve</div>
        <div className="text-success">{stats?.ServePoint || '-'}</div>
      </div>
      <div className="d-flex flex-column align-items-center">
        <div className="text-tertiary-100 text-xs mb-2">Total Serve Errors</div>
        <div className="text-destruction">{stats?.ServerError || '-'}</div>
      </div>
      <div className="d-flex flex-column align-items-center">
        <div className="text-tertiary-100 text-xs mb-2">Service Returns Wins</div>
        <div className="text-success">{stats?.ServeReturnWin || '-'}</div>
      </div>
      <div className="d-flex flex-column align-items-center">
        <div className="text-tertiary-100 text-xs mb-2">Service Returns Errors</div>
        <div className="text-destruction">{stats?.ServeReturnError || '-'}</div>
      </div>
      <div className="d-flex flex-column align-items-center">
        <div className="text-tertiary-100 text-xs mb-2">Win on 3rd ball</div>
        <div className="text-success">{stats?.PointsOn3rdBall || '-'}</div>
      </div>
      <div className="d-flex flex-column align-items-center">
        <div className="text-tertiary-100 text-xs mb-2">Errors on 3rd ball</div>
        <div className="text-destruction">{stats?.ErrorsOn3rdBall || '-'}</div>
      </div>
    </div>
  );
};
