import PlayerReceivesStats from './components/PlayerReceivesStats';
import TotalReceiveAnalysis from './components/TotalReceiveAnalysis';

export default function ReceiveAnalysis({ data }) {
  const {
    AIData: {
      AIResponse: {
        RecieveAnalysis: { PlayerA, PlayerB, PionWonOnShots },
      },
    },
  } = data;

  return (
    <>
      <div className="grid grid-cols-2 gap-4 mb-4 border-b-1">
        <PlayerReceivesStats data={PlayerA} />
        <PlayerReceivesStats data={PlayerB} />
      </div>

      <TotalReceiveAnalysis data={PionWonOnShots} />
    </>
  );
}
